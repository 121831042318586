@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
body {
  margin: 0;
  /* font-family: "Lato",
    sans-serif; */
  /* font-family: 'Inter',
    sans-serif; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* overflow-x: hidden; */
}


iframe{
  border-radius: 12px;
}

.chatbot{
  position: fixed;
  right:12px;
  bottom:24px;
  width: 52px;
  height: 52px;
  padding:0 12px;
  display: none;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
 background: #33CC66;
  border-radius: 50%;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 22px;
  color: #FFFFFF;
  text-decoration: none;
  z-index: 1;
}
.chatbot img{
  width: 32px;
  height: 32px;
}

@media(max-width:700px){

  .chatbot{
    display: flex;
  }
}
/* body{ 
    border: 2px solid #fff7f8;
   padding: 100px; 
 } */


 .shimmer-container{
    display: flex;
    margin: 80px 0;
 }

 .shimmer-left{
      width:48%;
      margin: 0 40px;
 }

 .shimmer-right{
    width:52%
 }

 .shimmer-bottom-fixed{
   position: fixed;
   bottom: 0;
 }
 
 .shimmer-shine {
   background: #f6f7f8;
   background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
   background-repeat: no-repeat;
   background-size: 800px 104px; 
   display: inline-block;
   position: relative; 
    
   animation-duration: 1s;
   animation-fill-mode: forwards; 
   animation-iteration-count: infinite;
   animation-name: placeholderShimmer;
   animation-timing-function: linear;

   -webkit-animation-duration: 1s;
   -webkit-animation-fill-mode: forwards; 
   -webkit-animation-iteration-count: infinite;
   -webkit-animation-name: placeholderShimmer;
   -webkit-animation-timing-function: linear;
   }
 

bigbox{
  height: 300px;
  width: 100%;
  
}

 box {
   height: 104px;
   width: 100px;
 }
/*  
 div {
   display: inline-flex;
   flex-direction: column; 
   margin-left: 25px;
   margin-top: 15px;
   vertical-align: top; 
 } */
 
 lines {
   height: 10px;
   margin-top: 10px;
   width: 200px; 
 }
 
 photo {
   display: block!important;
   width: 325px; 
   height: 100px; 
   margin-top: 15px;
 }
 

 @keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }
    
    100% {
      background-position: 468px 0; 
    }
  }

 @-webkit-keyframes placeholderShimmer {
   0% {
     background-position: -468px 0;
   }
   
   100% {
     background-position: 468px 0; 
   }
 }
 
 

 @media(max-width:1000px){

        .shimmer-container{
          display: flex;
          flex-direction: column;
          margin: 24px;
        }

        .shimmer-left{
            width:100%;
            margin: 0;
        }

        .shimmer-right{
          width:100%
        }

        .shimmer-bottom-fixed{
          position: static;
          position: initial;
        }


 }
