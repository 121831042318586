.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* overflow-x: hidden; */
}


iframe{
  border-radius: 12px;
}

.chatbot{
  position: fixed;
  right:12px;
  bottom:24px;
  width: 52px;
  height: 52px;
  padding:0 12px;
  display: none;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
 background: #33CC66;
  border-radius: 50%;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 22px;
  color: #FFFFFF;
  text-decoration: none;
  z-index: 1;
}
.chatbot img{
  width: 32px;
  height: 32px;
}

@media(max-width:700px){

  .chatbot{
    display: flex;
  }
}